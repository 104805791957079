import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Agilidade no Atendimento",
          "Centralização da Comunicação",
          "Relatórios e Análises",
          "Organização e Priorização",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
